import { Backdrop, Box, CircularProgress, Stack, Typography } from '@mui/material';

interface CenteredProgressProps {
  label?: string;
}

export const CenteredProgress = ({ label }: CenteredProgressProps) => {
  return (
    <Backdrop sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open>
      <Stack display="flex" justifyContent="center" alignItems="ceneter">
        <Stack direction="row">
          <Box sx={{ flex: '1 1 auto' }} />
          <CircularProgress color="inherit" />
          <Box sx={{ flex: '1 1 auto' }} />
        </Stack>
        {label && (
          <Typography mt={2} align="center">
            {label}
          </Typography>
        )}
      </Stack>
    </Backdrop>
  );
};
