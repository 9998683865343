import { ThemeProvider } from '@mui/material/styles';
import { UserDataProvider } from './context/UserData';
import { ProductDataProvider } from './context/ProductData';
import { TemplateDataProvider } from './context/TemplateData';
import { ImageKeywordDataProvider } from './context/ImageKeywordData';
import { TemplateKeywordDataProvider } from './context/TemplateKeywordData';
import { SnackbarContextProvider } from './context/SnackbarContext';
import { Theme } from './styles/Theme';
import { Router } from './components/basic/Router';
import { PosterImageDataProvider } from './context/PosterImageData';
import { PromoDataProvider } from './context/PromoData';
import { CenteredProgress } from './components/basic/CenteredProgress';
import { Suspense } from 'react';

export const App = () => {
  return (
    <Suspense fallback={<CenteredProgress />}>
      <ProductDataProvider>
        <PromoDataProvider>
          <TemplateDataProvider>
            <UserDataProvider>
              <PosterImageDataProvider>
                <ImageKeywordDataProvider>
                  <TemplateKeywordDataProvider>
                    <ThemeProvider theme={Theme}>
                      <SnackbarContextProvider>
                        <Router />
                      </SnackbarContextProvider>
                    </ThemeProvider>
                  </TemplateKeywordDataProvider>
                </ImageKeywordDataProvider>
              </PosterImageDataProvider>
            </UserDataProvider>
          </TemplateDataProvider>
        </PromoDataProvider>
      </ProductDataProvider>
    </Suspense>
  );
};
