import { Box, IconButton, Stack } from '@mui/material';
import { useState } from 'react';
import { useInterval } from '../../hooks/useInterval';
import { motion } from 'framer-motion';
import { SvgIcon } from '@mui/material';
import { DotFilledSvg, DotSvg } from '../../styles/Icons';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const images = ['image1.webp', 'image2.webp', 'image3.webp'];

export const ImageGallery = () => {
  const [imageIdx, setImageIdx] = useState<number>(0);
  const [delay, setDelay] = useState<number>(4000);

  useInterval(async () => {
    const idx = imageIdx + 1;
    setImageIdx(idx % images.length);
    setDelay(4000);
  }, delay);

  const updateImage = (addIt: number) => {
    const idx = imageIdx + addIt;
    setImageIdx((idx + images.length) % images.length);
    setDelay(8000);
  };

  return (
    <Stack direction="row">
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />

      <Box
        position="relative"
        sx={{
          maxHeight: { xs: 640, md: 640 },
          maxWidth: { xs: '100%', md: 640 },
        }}
      >
        <Box
          component="img"
          position="relative"
          sx={{
            maxHeight: { xs: 640, md: 640 },
            maxWidth: { xs: '100%', md: 640 },
          }}
          alt="Custom poster prints to decorate your space."
          src="sofa_l.webp"
          srcSet="sofa_s.webp 430w, sofa_l.webp 640w"
          sizes="(min-width: 640px) 6400px, 100vw"
        />

        <Stack
          spacing={0}
          direction={'row'}
          sx={{ position: 'absolute', left: '50%', top: '6%', transform: 'translate(-50%, -50%)' }}
        >
          <Box mr={{ xs: 0, sm: 3 }}>
            <IconButton aria-label="Previous Image" onClick={() => updateImage(-1)}>
              <ChevronLeftIcon color="primary" fontSize="large" />
            </IconButton>
          </Box>
          <Box mt={1.5}>
            {images.map((_, idx) => {
              return idx === imageIdx ? (
                <SvgIcon key={idx} sx={{ transform: 'scale(0.9)', fill: 'white' }}>
                  {DotFilledSvg}
                </SvgIcon>
              ) : (
                <SvgIcon key={idx} sx={{ transform: 'scale(0.9)', fill: 'white' }}>
                  {DotSvg}
                </SvgIcon>
              );
            })}
          </Box>
          <Box ml={{ xs: 0, sm: 3 }}>
            <IconButton aria-label="Next Image" onClick={() => updateImage(1)}>
              <ChevronRightIcon color="primary" fontSize="large" />
            </IconButton>
          </Box>
        </Stack>

        <motion.div
          key={imageIdx}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2 }}
          sx={{
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
        >
          <Box
            component="img"
            sx={{
              maxHeight: { xs: 120, md: 120 },
              maxWidth: { xs: '30%', md: 200 },
              position: 'absolute',
              left: '50%',
              top: '30%',
              transform: 'translate(-50%, -50%)',
            }}
            alt="Custom poster prints to decorate your space."
            src={images[imageIdx]}
          />
        </motion.div>
      </Box>

      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />
    </Stack>
  );
};
