import { Helmet } from 'react-helmet';

export const HomePageStructuredData = () => {
  return (
    <Helmet>
      <title>{`Personalized Posters and Sports Banners`}</title>
      <meta
        name="description"
        content={`Custom printed fabric posters and personalized inspirational youth sports banners`}
      />
      <meta
        name="keywords"
        content={[
          'poster',
          'fabric',
          'custom',
          'banner',
          'personalized',
          'youth sports',
          'printed',
          'home decor',
          'personlized sports banners',
          'personalized youth sports banners',
        ].join(',')}
      />
    </Helmet>
  );
};
