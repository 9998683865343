import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { PosterCropDialog } from './PosterCropDialog';
import { PercentCrop } from 'react-image-crop';
import { v4 } from 'uuid';
import API from '../../api/API';
import { UserData } from '../../context/UserData';
import axios from 'axios';
import { SnackbarContext } from '../../context/SnackbarContext';
import { useNavigate } from 'react-router-dom';
import { CartItem, PosterImage, Product } from 'canvas-common';
import { CloseButton } from '../basic/CloseButton';
import { BorderColorSelector } from './BorderColorSelector';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { rgbToHex } from '../../utils/rgbToHex';
import { ProductData } from '../../context/ProductData';
import { CenteredProgress } from '../basic/CenteredProgress';

export const CustomPosterConfigurator = () => {
  const [uploading, setUploading] = useState<boolean>(true);
  const [posterImage, setPosterImage] = useState<PosterImage | undefined>();
  const [selectingProduct, setSelectingProduct] = useState<boolean>(false);
  const [color, setColor] = useState<[number, number, number] | null>(null);
  const [busy, setBusy] = useState<boolean>(false);

  const { errorSnack, successSnack } = useContext(SnackbarContext);
  const navigate = useNavigate();
  const { getProduct }: any = useContext(ProductData);

  const apiClient = API();
  const { cart, syncCartItems, uploadFile } = useContext(UserData);

  const handleCropComplete = (file: File, crop: PercentCrop | undefined) => {
    setUploading(false);
    uploadPhoto(file, crop);
  };

  const uploadPhoto = async (file: File, crop: PercentCrop | undefined) => {
    if (file) {
      try {
        setBusy(true);
        const uuid = v4();
        const s3Key = `${cart.userUUID!}/${uuid}`;
        const { url: s3BucketUrl, fields } = await apiClient.getS3BucketUploadUrl(
          cart.userUUID!,
          uuid
        );

        // Upload image to S3 bucket
        const form = new FormData();
        Object.entries(fields).forEach(([field, val]) => form.append(field, val as string));
        form.append('Content-Type', file.type);
        form.append('file', file);
        await axios.post(s3BucketUrl, form);
        const poster = await apiClient.cropAndResizePoster(s3Key, crop);
        setPosterImage({ ...poster, customS3Key: s3Key });

        successSnack('Image uploaded.');
        setSelectingProduct(true);
      } catch (err) {
        errorSnack('Oops! We had an error when uploading this image. Please try again.');
        setPosterImage(undefined);
      } finally {
        setBusy(false);
      }
    }
  };

  const handleAddToCart = () => {
    const product: Product = getProduct(
      posterImage?.orientation === 'square' ? '24_24_ADSV' : '24_36_ADSV'
    );
    const cartItem: CartItem = {
      id: v4(),
      product,
      posterImage: { ...posterImage, custom: true, ...(color && { borderColor: color }) },
      quantity: 1,
    };
    syncCartItems({
      cart: { ...cart, cartItems: [...cart.cartItems!, cartItem] },
      createOrUpdatePayment: false,
      calculateTax: false,
    });
    successSnack('Nice! The poster has been added to your shopping cart.');
    navigate('/posters');
  };

  const handleCropCanceled = (message?: string) => {
    if (message) {
      errorSnack(message);
    }
    navigate('/posters');
  };

  if (!uploadFile) navigate('/posters');

  const xs = color ? `20px solid ${rgbToHex(color)}` : 'none';
  const sm = color ? `40px solid ${rgbToHex(color)}` : 'none';

  if (busy) return <CenteredProgress label="Processing..." />;

  return (
    <>
      {uploading && (
        <PosterCropDialog
          file={uploadFile!}
          origCrop={undefined}
          handleCropCanceled={handleCropCanceled}
          handleCropComplete={handleCropComplete}
        />
      )}

      {selectingProduct && (
        <>
          <CloseButton onClose={() => navigate('/posters')} />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack direction="column" spacing={2}>
              <Box>
                <Box
                  maxWidth="min(90vw, 700px)"
                  maxHeight="700px"
                  component="img"
                  src={`https://templates.${
                    process.env.REACT_APP_STAGE
                  }.canvaspaints.com/customer/${posterImage!.pk}_l.jpg`}
                  sx={{ border: { xs, sm } }}
                  // onLoad={() => console.log('LOADED BABY!!!')}
                />
              </Box>

              <BorderColorSelector colorPalette={posterImage!.colorPalette!} setColor={setColor} />

              <Box>
                <Divider />
                <Stack
                  mt={2}
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 0, sm: 2 }}
                  alignItems={'center'}
                >
                  {posterImage?.orientation === 'square' && (
                    <Typography>24"x24" Adhesive Fabric Poster - $39.95</Typography>
                  )}
                  {posterImage?.orientation !== 'square' && (
                    <Typography>24"x36" Adhesive Fabric Poster - $49.95</Typography>
                  )}

                  <Button
                    startIcon={<AddShoppingCartIcon />}
                    variant="contained"
                    onClick={handleAddToCart}
                  >
                    Add To Cart
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};
