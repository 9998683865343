import { Box, Input } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ChangeEvent, useContext } from 'react';
import { UserData } from '../../context/UserData';
import { useNavigate } from 'react-router-dom';
// import { motion } from 'framer-motion';

export const CustomPosterCard = () => {
  const navigate = useNavigate();

  const { setUploadFile } = useContext(UserData);

  const cropPhoto = (event: ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = event.target.files?.[0];
    if (file) {
      setUploadFile(file);
      navigate('/poster/custom');
    }
  };

  return (
    <Grid
      key={'custom'}
      size={{ xs: 12, sm: 6, md: 4, lg: 3 }}
      // component={motion.div}
      // variants={{
      //   hidden: { opacity: 0, scale: 0.7 },
      //   visible: { opacity: 1, scale: 1 },
      // }}
      // transition={{ type: 'spring' }}
    >
      <Box justifyItems="center">
        <Box position={'relative'} sx={{ aspecRatio: '1/1' }}>
          <Box
            justifyItems={'center'}
            sx={{
              height: '100%',
              width: '100%',
              objectFit: 'cover',
            }}
            component="img"
            src={'custom.webp'}
            alt="Custom poster prints to decorate your space."
          />
          <Input
            sx={{ display: 'none' }}
            id="contained-button-file"
            onChange={(e) => cropPhoto(e as ChangeEvent<HTMLInputElement>)}
            type="file"
            inputProps={{ accept: 'image/jpeg, image/jpg' }}
          />
          <label htmlFor="contained-button-file">
            <Box
              component="span"
              sx={{
                position: 'absolute',
                border: 'none',
                backgroundColor: 'white',
                left: 0,
                top: 0,
                minWidth: '100%',
                minHeight: '100%',
                opacity: 0,
                '&:hover': { opacity: '50%', cursor: 'zoom-in' },
              }}
            ></Box>
          </label>
        </Box>
      </Box>
    </Grid>
  );
};
