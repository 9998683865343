import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const SportsBannerImage = () => {
  const navigate = useNavigate();

  return (
    <Stack direction="row">
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />

      <Box
        mt={3}
        component={Stack}
        spacing={2}
        direction="column"
        justifyContent="center"
        sx={{ padding: '10px', minHeight: { xs: 300, lg: 600 } }}
      >
        <Stack direction={{ xs: 'column', lg: 'row' }} alignItems="center" spacing={4}>
          <Stack spacing={4}>
            <Typography
              variant="h4"
              sx={{
                textAlign: 'center',
              }}
            >
              Youth Sports
            </Typography>

            <Typography
              variant="h5"
              sx={{
                textAlign: 'center',
              }}
            >
              At CanvasPaints, we believe that every young athlete deserves to be celebrated. Sports
              are more than just games—they teach discipline, teamwork, and perseverance. That's why
              we create Personalized Sports Banners that highlight each player's journey, making
              them feel like the champions they truly are.
            </Typography>

            <Typography
              variant="h5"
              sx={{
                textAlign: 'center',
              }}
            >
              We know how much effort parents, coaches, and teams put into supporting young
              athletes. That's why we make the process simple, fast, and stress-free. Inspire your
              young athlete today with a Personalized Sports Banner featuring their favorite team.
            </Typography>
          </Stack>
          <Box
            component="img"
            src="bedroom.webp"
            alt="Banner displayed in bedroom"
            maxWidth={'80%'}
          />
        </Stack>

        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
          <Button variant="contained" color="secondary" onClick={() => navigate('/sports-banners')}>
            Create Your Banner
          </Button>
        </Stack>
      </Box>

      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />
    </Stack>
  );
};
