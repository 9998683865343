import Grid from '@mui/material/Grid2';
import { PosterCard } from '../posters/PosterCard';
import { Box, Stack } from '@mui/material';
import { KeywordPanel } from '../posters/KeywordPanel';
import { useContext, useEffect, useState } from 'react';
import { UserData } from '../../context/UserData';
import { CustomPosterCard } from '../posters/CustomPosterCard';
import { PosterImage } from 'canvas-common';
import { PosterImageData } from '../../context/PosterImageData';
// import { AnimatePresence, motion } from 'framer-motion';
import { v4 } from 'uuid';
import InfiniteScroll from 'react-infinite-scroller';
import { CenteredProgress } from '../basic/CenteredProgress';
import { AllPostersStructuredData } from '../structuredData/AllPostersStructredData';

export const AllPostersView = () => {
  const [images, setImages] = useState<Partial<PosterImage>[]>([]);
  const { favorites, posterScrollLocation, posterIndex, setPosterIndex } = useContext(UserData);
  const [keywords, setKeywords] = useState<string[]>([]);
  const { posterImages, posterImagesLoading } = useContext(PosterImageData);

  useEffect(() => {
    if (posterImagesLoading) return;
    let newImages = posterImages.slice(0, posterIndex || 10);
    if (keywords.includes('favorites')) {
      newImages = posterImages.filter((i) => favorites.includes(i.pk!));
    }
    const otherKeywords = keywords.filter((w) => w !== 'favorites');
    if (otherKeywords.length > 0) {
      newImages = posterImages.filter((i) => i.keywords?.some((kw) => otherKeywords.includes(kw)));
    }
    setImages(newImages);
  }, [favorites, keywords, posterImages, posterImagesLoading, posterIndex]);

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, posterScrollLocation), 200);
  }, [posterScrollLocation]);

  if (posterImagesLoading) return <CenteredProgress label="Loading..." />;

  const loadMore = () => {
    if (posterIndex < posterImages.length && keywords.length === 0) {
      const next = Math.min(10, posterImages.length - posterIndex + 1);
      setImages([...images, ...posterImages.slice(posterIndex, posterIndex + next)]);
      setPosterIndex(posterIndex + next);
    }
  };

  return (
    <>
      <AllPostersStructuredData />
      <KeywordPanel
        updateKeywords={(words) => setKeywords(words)}
        words={'IMAGE'}
        includeFavs={true}
      />

      <Stack direction={'row'} mx={3}>
        <Box sx={{ flexGrow: 1, display: 'flex' }} />

        {images && (
          <Box maxWidth={'1800px'}>
            {/* <AnimatePresence> */}
            <InfiniteScroll
              pageStart={0}
              loadMore={loadMore}
              hasMore={posterIndex < posterImages.length && keywords.length === 0}
              loader={
                <div className="loader" key="loader">
                  Loading ...
                </div>
              }
            >
              <Grid
                key={v4()}
                container
                spacing={2}
                // component={motion.div}
                // variants={{ visible: { transition: { staggerChildren: 0.05 } } }}
                // initial="hidden"
                // animate="visible"
              >
                {keywords.length === 0 && <CustomPosterCard />}
                {images.map((i) => (
                  <PosterCard key={i.pk} image={i} numImages={images.length} />
                ))}
              </Grid>
            </InfiniteScroll>
            {/* </AnimatePresence> */}
          </Box>
        )}

        <Box sx={{ flexGrow: 1, display: 'flex' }} />
      </Stack>
    </>
  );
};
