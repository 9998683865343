import { createContext, useEffect, useState } from 'react';
import { Promo } from 'canvas-common';
import API from '../api/API';

type PromoDataContextDefaultValue = {
  promo: Promo | undefined;
  promosLoading: boolean;
};

export const PromoData = createContext<PromoDataContextDefaultValue>({
  promo: undefined,
  promosLoading: false,
});

export const PromoDataProvider = ({ children }: any) => {
  const apiClient = API();

  const [promo, setPromo] = useState<Promo | undefined>();
  const [promosLoading, setPromosLoading] = useState<boolean>(true);

  useEffect(() => {
    const initializePromos = async () => {
      const bePromos = await apiClient.getAllPromos();
      setPromo(bePromos?.[0]);
      setPromosLoading(false);
    };

    initializePromos();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    promo,
    promosLoading,
  };

  return <PromoData.Provider value={value}>{children}</PromoData.Provider>;
};
