import { createContext, useEffect, useState } from 'react';
import API from '../api/API';
import { PosterImage } from 'canvas-common';

type PosterImageDataContextDefaultValue = {
  posterImages: PosterImage[];
  posterImagesLoading: boolean;
};

export const PosterImageData = createContext<PosterImageDataContextDefaultValue>({
  posterImages: [],
  posterImagesLoading: false,
});

export const PosterImageDataProvider = ({ children }: any) => {
  const apiClient = API();

  const [posterImages, setPosterImage] = useState<PosterImage[]>([]);
  const [posterImagesLoading, setPosterImagesLoading] = useState<boolean>(true);

  useEffect(() => {
    const initializeImages = async () => {
      const images = await apiClient.getAllImages();
      images.sort((a: any, b: any) => {
        return a.index - b.index;
      });
      setPosterImage(images);
      setPosterImagesLoading(false);
    };

    initializeImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    posterImages,
    posterImagesLoading,
  };

  return <PosterImageData.Provider value={value}>{children}</PosterImageData.Provider>;
};
