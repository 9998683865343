import {
  Autocomplete,
  Badge,
  BadgeProps,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useContext, useState } from 'react';
import { UserData } from '../../context/UserData';
import { v4 } from 'uuid';
import { ImageKeywordData } from '../../context/ImageKeywordData';
import { TemplateKeywordData } from '../../context/TemplateKeywordData';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

interface KeywordPanelProps {
  updateKeywords: (keywords: string[]) => void;
  words: 'TEMPLATE' | 'IMAGE';
  includeFavs: boolean;
  label?: string;
}

export const KeywordPanel = ({ updateKeywords, words, includeFavs, label }: KeywordPanelProps) => {
  const { favorites } = useContext(UserData);
  const { imageKeywords, imageKeywordsLoading } = useContext(ImageKeywordData);
  const { templateKeywords, templateKeywordsLoading } = useContext(TemplateKeywordData);

  const [keywords, setKeywords] = useState<string[]>([]);
  const [keyword, setKeyword] = useState<string | null>(null);

  let autoKey = v4();

  const clearKeyword = (word: string) => {
    const newKeywords = [...keywords].filter((w) => w !== word);
    setKeywords(newKeywords);
    updateKeywords(newKeywords);
  };

  const addKeyword = (word: string) => {
    const lc = word.toLowerCase();
    if (!keywords.includes(lc)) {
      setKeywords([...keywords, lc]);
    }
    updateKeywords([...keywords, lc]);
  };

  if (words === 'IMAGE' && imageKeywordsLoading) return <CircularProgress />;
  if (words === 'TEMPLATE' && templateKeywordsLoading) return <CircularProgress />;

  const theKeywords = words === 'IMAGE' ? imageKeywords : templateKeywords;

  return (
    <>
      <Stack direction={'row'} mx={{ xs: 1, sm: 3 }} mb={1}>
        <Box sx={{ flexGrow: 1, display: 'flex' }} />

        {includeFavs && (
          <Box mx={{ xs: 1, sm: 3 }}>
            <Tooltip title="Show Favorites">
              <IconButton
                aria-label="favorites"
                onClick={() => {
                  addKeyword('favorites');
                }}
              >
                <StyledBadge badgeContent={favorites?.length || 0} color="primary">
                  <FavoriteIcon />
                </StyledBadge>
              </IconButton>
            </Tooltip>
          </Box>
        )}

        <Autocomplete
          key={autoKey}
          fullWidth
          disablePortal
          value={keyword}
          options={theKeywords}
          onChange={(ev, value) => {
            if (value) addKeyword(value);
            setKeyword(null);
            autoKey = v4();
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                label={label || 'Search By Keyword'}
                slotProps={{
                  input: {
                    ...params.InputProps,
                    startAdornment: (
                      <>
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      </>
                    ),
                  },
                }}
              />
            );
          }}
        />
        <Box sx={{ flexGrow: 1, display: 'flex' }} />
      </Stack>

      <Box ml={8} mb={4}>
        {keywords.length > 0 && (
          <Typography display="inline" sx={{ mr: 2 }}>
            Keywords:
          </Typography>
        )}
        {keywords.map((word) => {
          return <Chip key={word} label={word} onDelete={() => clearKeyword(word)} />;
        })}
      </Box>
    </>
  );
};
