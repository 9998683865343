import { createContext, useState, useCallback, useEffect } from 'react';
import { v4 } from 'uuid';
import { Cart } from 'canvas-common';
import API from '../api/API';
import { CartUpdateBody } from 'canvas-common';

export type UserDataContext = {
  cart: Cart;
  clearCart: () => Promise<Cart>;
  syncCartItems: (update: CartUpdateBody) => Promise<Cart>;
  setCart: (cart: Cart) => void;

  favorites: string[];
  addFavorite: (favorite: string) => void;
  removeFavorite: (favorite: string) => void;

  uploadFile: File | null;
  setUploadFile: (file: File | null) => void;

  posterScrollLocation: number;
  setPosterScrollLocation: (loc: number) => void;

  posterIndex: number;
  setPosterIndex: (index: number) => void;

  templateScrollLocation: number;
  setTemplateScrollLocation: (loc: number) => void;
};

export const UserData = createContext<UserDataContext>({
  cart: new Cart({}),
  favorites: [],
  clearCart: () =>
    new Promise((resolve, reject) => {
      new Cart({});
    }),
  syncCartItems: (update) =>
    new Promise((resolve, reject) => {
      new Cart({});
    }),
  setCart: (cart: Cart) => {},
  addFavorite: (favorite) => {},
  removeFavorite: (favorite) => {},
  uploadFile: null,
  setUploadFile: (file) => {},
  posterScrollLocation: 0,
  setPosterScrollLocation: () => {},
  posterIndex: 0,
  setPosterIndex: () => {},
  templateScrollLocation: 0,
  setTemplateScrollLocation: () => {},
});

export const UserDataProvider = ({ children }: any) => {
  const [cart, setCart] = useState<Cart>({ cartItems: [] });
  const [favorites, setFavorites] = useState<string[]>([]);
  const [uploadFile, setUploadFile] = useState<File | null>(null);
  const [templateScrollLocation, setTemplateScrollLocation] = useState<number>(0);
  const [posterScrollLocation, setPosterScrollLocation] = useState<number>(0);
  const [posterIndex, setPosterIndex] = useState<number>(0);

  const apiClient = API();
  const syncTokens = useCallback((uToken: string, aToken: string, rToken: string) => {}, []);

  useEffect(() => {
    const intializeUser = async () => {
      let localUser = localStorage.getItem('userUUID');
      if (!localUser) {
        localUser = v4();
        localStorage.setItem('userUUID', localUser);
        setCart({ userUUID: localUser, cartItems: [] });
      } else {
        const newCart = await apiClient.getAnonCart(localUser);
        setCart(newCart);
      }

      const favs = localStorage.getItem('Favorites');
      setFavorites(favs ? JSON.parse(favs) : []);
    };

    intializeUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateCart = async (update: CartUpdateBody) => {
    const newCart = await apiClient.updateCart(update);
    setCart(newCart);
    return newCart;
  };

  const clearCart = async () => {
    await apiClient.deleteCart(cart.userUUID as string);
    const newCart = { userUUID: cart.userUUID, cartItems: [], email: cart.email };
    setCart(newCart);
    return newCart;
  };

  const syncCartItems = useCallback(async (update: CartUpdateBody) => {
    const newCart = await updateCart(update);
    return newCart;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addFavorite = (favorite: string) => {
    if (!favorites.includes(favorite)) {
      setFavorites([...favorites, favorite]);
      localStorage.setItem('Favorites', JSON.stringify([...favorites, favorite]));
    }
  };

  const removeFavorite = (favorite: string) => {
    if (favorites.includes(favorite)) {
      const newFavs = [...favorites].filter((f) => f !== favorite);
      setFavorites(newFavs);
      localStorage.setItem('Favorites', JSON.stringify(newFavs));
    }
  };

  const value = {
    cart,
    setCart,
    favorites,
    syncTokens,
    clearCart,
    syncCartItems,
    addFavorite,
    removeFavorite,
    uploadFile,
    setUploadFile,
    posterScrollLocation,
    setPosterScrollLocation,
    posterIndex,
    setPosterIndex,
    templateScrollLocation,
    setTemplateScrollLocation,
  };

  return <UserData.Provider value={value}>{children}</UserData.Provider>;
};
