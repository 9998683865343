import { Box, Button, Input, Stack } from '@mui/material';
import CropIcon from '@mui/icons-material/Crop';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import ReactCrop, { centerCrop, makeAspectCrop, Crop, PercentCrop } from 'react-image-crop';
import { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';
import 'react-image-crop/dist/ReactCrop.css';

const styles = {
  hidden: { display: 'none' },
};

interface PosterCropDialogProps {
  file: File;
  origCrop: PercentCrop | undefined;
  handleCropComplete: (file: File, crop: PercentCrop | undefined) => void;
  handleCropCanceled: (message?: string) => void;
}

const centerAspectCrop = (mediaWidth: number, mediaHeight: number, aspect: number) => {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
};

export const PosterCropDialog = ({
  file,
  origCrop,
  handleCropComplete,
  handleCropCanceled,
}: PosterCropDialogProps) => {
  const [imgSrc, setImgSrc] = useState('');
  const [imgFile, setImgFile] = useState(file);
  const [aspectRatio, setAspectRatio] = useState<number>(0);
  const imgRef = useRef<HTMLImageElement>(null);
  const [crop, setCrop] = useState<Crop>();
  const [completedCrop, setCompletedCrop] = useState<PercentCrop>();

  useEffect(() => {
    const reader = new FileReader();
    if (file.type !== 'image/jpeg') {
      handleCropCanceled('Only JPEG images are supported. Please select a JPEG image.');
    } else {
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onImageLoad = (e: SyntheticEvent<HTMLImageElement>) => {
    try {
      const { width, height } = e.currentTarget;
      const ratio = width / height;
      const sqCheck = Math.abs(1 - ratio);
      const ar = sqCheck < 0.2 ? 1 : ratio > 1 ? 3 / 2 : 2 / 3;
      const theCrop = origCrop || centerAspectCrop(width, height, ar);
      setAspectRatio(ar);
      setCrop(theCrop);
    } catch (e) {
      handleCropCanceled('Oops! We had an error with this image. Please try a different image.');
    }
  };

  const updateImage = (event: ChangeEvent<HTMLInputElement>) => {
    const newFile: File | undefined = event.target.files?.[0];
    if (newFile) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
      reader.readAsDataURL(newFile);
      setImgFile(newFile);
    }
  };

  return (
    <Stack direction="column" display="flex" justifyContent="center" alignItems="center">
      <Box>
        {!!imgSrc && (
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => {
              setCrop(percentCrop);
            }}
            onComplete={(_, percentCrop) => {
              setCompletedCrop(percentCrop);
            }}
            aspect={aspectRatio}
          >
            <Box
              component="img"
              width={{ xs: '250px', md: '500px' }}
              ref={imgRef}
              alt="Crop"
              src={imgSrc}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        )}
      </Box>

      <Stack direction={{ xs: 'column', md: 'row' }} mt={1} spacing={2}>
        <Button
          fullWidth
          variant="outlined"
          onClick={() => handleCropComplete(imgFile, completedCrop)}
          startIcon={<CropIcon />}
        >
          Crop and Upload
        </Button>
        <Input
          sx={styles.hidden}
          id="update-file-button"
          onChange={(e) => updateImage(e as ChangeEvent<HTMLInputElement>)}
          type="file"
          inputProps={{ accept: 'image/jpeg, image/jpg' }}
        />
        <label htmlFor="update-file-button">
          <Button fullWidth variant="outlined" component="span" startIcon={<CloudUploadIcon />}>
            Change Photo...
          </Button>
        </label>
        <Button fullWidth variant="outlined" onClick={() => handleCropCanceled()}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};
