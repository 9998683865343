"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Promo = void 0;
var Promo = /** @class */ (function () {
    function Promo(data) {
        Object.assign(this, data);
    }
    Promo.getCurrentPrice = function (promo, product) {
        if (!promo)
            return { discounted: false, price: product.price };
        var discount = promo.discountOnSku(product.data);
        var discounted = discount.amount > 0;
        var price = product.price;
        if (discounted) {
            price =
                discount.discountType === 'amount'
                    ? product.price - discount.amount
                    : product.price * (1 - discount.amount);
        }
        return { discounted: discounted, price: price };
    };
    Promo.prototype.discountOnSku = function (sku) {
        if (this.type === 'sitewide' || (this.type === 'product' && this.skus.includes(sku))) {
            return { amount: this.amount, discountType: this.discountType };
        }
        return { amount: 0, discountType: 'amount' };
    };
    return Promo;
}());
exports.Promo = Promo;
/*

Query patterns:
 - Get all site promos:
    - Query: sk beginsWith 'site_'

  - Get promo by code:
    - Query: sk === 'personal' and data === 'code'

title: '20% off sitewide',
sk: 'site_sitewide',
data: '20OFF',
discountType: 'percent',
amount: .20,

title: '$10 off sitewide',
sk: 'site_sitewide',
data: '10OFF',
discountType: 'amount',
amount: 10,

title: '10% off all adhesive posters',
sk: 'site_product',
data: 'ADHESIVE10',
skus: ['ADHESIVE'],
discountType: 'percent',
amount: .10,

title: 'Free shipping on all orders',
sk: 'site_shipping',
data: 'FREESHIP01',
discountType: 'percent',
amount: 1.0,

title: 'Personal to appease customer',
sk: 'personal',
data: 'x6x8g0d7',
discountType: 'amount',
amount: 57.65,

*/
