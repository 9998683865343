import { Box, Button, Divider, Input, Stack, Tooltip, Typography } from '@mui/material';
import { ChangeEvent, useEffect, useState, useContext } from 'react';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { v4 } from 'uuid';
import axios from 'axios';
import {
  CartItem,
  PercentCrop,
  PersonalizedImage,
  PersonalizedTemplate,
  Product,
  Promo,
  Template,
} from 'canvas-common';
import API from '../../api/API';
import { PhotoCropDialog } from './PhotoCropDialog';
import { UserData } from '../../context/UserData';
import { ProductData } from '../../context/ProductData';
import { SnackbarContext } from '../../context/SnackbarContext';
import { CloseButton } from '../basic/CloseButton';
import { CenteredProgress } from '../basic/CenteredProgress';
import { PromoData } from '../../context/PromoData';
import { formatCurrency } from '../../styles/Formatters';

interface PersonalizedDialogProps {
  template: Template;
  onClose: () => void;
}

export const PersonalizeDialog = ({ template, onClose }: PersonalizedDialogProps) => {
  const [uploadOpen, setUploadOpen] = useState(false);
  const [templateImage, setTemplateImage] = useState<string | undefined>(undefined);
  const [files, setFiles] = useState(new Array(template.images.length));
  const [cropFile, setCropFile] = useState<File | undefined>(undefined);
  const [cropIndex, setCropIndex] = useState<number>(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [scale, setScale] = useState<number>(0.5); // allow zoom in future
  const { errorSnack, successSnack } = useContext(SnackbarContext);
  const [images, setImages] = useState<PersonalizedImage[]>(
    template.images.map((img) => {
      return { rect: img.rect, index: img.index };
    })
  );

  const { productsLoading, getProduct }: any = useContext(ProductData);
  const { promosLoading, promo } = useContext(PromoData);
  const { cart, syncCartItems } = useContext(UserData);

  const apiClient = API();

  useEffect(() => {
    const initialRender = async () => {
      const body = { templateId: template.data, images: [] };
      const data = await apiClient.renderTemplate(body);
      setTemplateImage(data);
    };

    initialRender();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAddToCart = (prod: string) => {
    const product: Product = getProduct(prod);
    const finalImages = images.map((img) => {
      const templateImg = template.images.filter((i) => i.index === img.index)[0];
      const productionRect = templateImg.productionRects.filter(
        (pr) => pr.productWidth === product.width
      )[0].rect;
      return { ...img, productionRect };
    });
    const personalizedTemplate: PersonalizedTemplate = {
      template,
      images: finalImages,
    };
    const cartItem: CartItem = {
      id: v4(),
      product,
      personalizedTemplate,
      quantity: 1,
    };
    syncCartItems({
      cart: { ...cart, cartItems: [...cart.cartItems!, cartItem] },
      createOrUpdatePayment: false,
      calculateTax: false,
    });
    successSnack('Nice! The banner has been added to your shopping cart.');
    onClose();
  };

  const handleCropComplete = (file: File, crop: PercentCrop | undefined, index: number) => {
    const newImages = [...images];
    newImages[index].crop = crop;
    setImages(newImages);
    const newFiles = [...files];
    newFiles[index] = file;
    setFiles(newFiles);
    setUploadOpen(false);
    uploadPhoto(file, index);
  };

  const uploadPhoto = async (file: File, index: number) => {
    if (file) {
      try {
        setTemplateImage(undefined);
        const uuid = v4();
        // Get signed S3 bucket URL from backend
        const { url: s3BucketUrl, fields } = await apiClient.getS3BucketUploadUrl(
          cart.userUUID as string,
          uuid
        );

        // Upload image to S3 bucket
        const form = new FormData();
        Object.entries(fields).forEach(([field, val]) => form.append(field, val as string));
        form.append('Content-Type', file.type);
        form.append('file', file);
        await axios.post(s3BucketUrl, form);

        const newImages = [...images];
        newImages[index].cartS3Key = fields.key;
        setImages(newImages);

        const newS3image = await apiClient.cropAndResize(newImages[index]);
        const new2Images = [...images];
        new2Images[index].cartResizedS3Key = newS3image;
        setImages(new2Images);

        // Get the rendered template
        const body = { templateId: template.data, images: new2Images };
        const data = await apiClient.renderTemplate(body);
        setTemplateImage(data);
        successSnack('Image uploaded.');
      } catch (err) {
        errorSnack('Oops! We had an error when uploading this image. Please try again.');
        console.error(err);
      } finally {
        // Set template image to an error image
      }
    }
  };

  const cropPhoto = (event: ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = event.target.files?.[0];
    if (file) {
      setCropFile(file);
      setUploadOpen(true);
    }
  };

  const editExisting = (index: number) => {
    setCropIndex(index);
    setUploadOpen(true);
  };

  const complete = () => {
    const completed = files.filter((file) => file);
    return completed.length === images.length;
  };

  if (productsLoading || promosLoading) return <CenteredProgress />;

  const adsvProduct = getProduct('24_60_ADSV');
  const adsvPrice = Promo.getCurrentPrice(promo, adsvProduct);

  const polyProduct = getProduct('24_60_POLY');
  const polyPrice = Promo.getCurrentPrice(promo, polyProduct);

  return (
    <>
      {!uploadOpen && (
        <>
          <CloseButton onClose={onClose} />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack direction="column">
              <Typography px={4} variant="h5" gutterBottom>
                {template.title}
              </Typography>

              <Stack direction="row" display="flex" justifyContent="center">
                <Box mb={4} sx={{ position: 'relative', height: '750px', width: '300px' }}>
                  {!!templateImage && (
                    <img height="750px" src={templateImage} alt={'template-personalization'} />
                  )}
                  {!templateImage && <CenteredProgress label={'Processing...'} />}
                  {!!templateImage &&
                    template.images?.map((image) => {
                      return (
                        <div key={image.index}>
                          {!files[image.index] && (
                            <>
                              <Input
                                sx={{ display: 'none' }}
                                id="contained-button-file"
                                onChange={(e) => cropPhoto(e as ChangeEvent<HTMLInputElement>)}
                                type="file"
                                inputProps={{ accept: 'image/jpeg, image/jpg' }}
                              />

                              <label htmlFor="contained-button-file">
                                <Button
                                  component="span"
                                  onClick={() => setCropIndex(image.index)}
                                  sx={{
                                    color: '#fff',
                                    position: 'absolute',
                                    top: `${Math.round(image.rect.top * scale)}px`,
                                    right: `${Math.round(
                                      image.rect.right * scale - image.rect.width * scale
                                    )}px`,
                                    width: `${Math.round(image.rect.width * scale)}px`,
                                    height: `${Math.round(image.rect.height * scale)}px`,
                                    '&:hover': {
                                      backgroundColor: 'primary.main',
                                      color: '#000',
                                    },
                                  }}
                                >
                                  Upload Photo...
                                </Button>
                              </label>
                            </>
                          )}

                          {files[image.index] && (
                            <>
                              <Button
                                component="span"
                                onClick={() => editExisting(image.index)}
                                sx={{
                                  color: '#000',
                                  position: 'absolute',
                                  top: `${Math.round(image.rect.top * scale)}px`,
                                  right: `${Math.round(
                                    image.rect.right * scale - image.rect.width * scale
                                  )}px`,
                                  width: `${Math.round(image.rect.width * scale)}px`,
                                  height: `${Math.round(image.rect.height * scale)}px`,
                                  '&:hover': {
                                    backgroundColor: 'primary.main',
                                  },
                                  '&:hover:before': {
                                    content: `${'"Update Photo..."'}`,
                                  },
                                }}
                              ></Button>
                            </>
                          )}
                        </div>
                      );
                    })}
                </Box>
              </Stack>

              <Box>
                <Divider />
                <Stack
                  my={2}
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 0, sm: 2 }}
                  alignItems={'center'}
                >
                  <Typography>{'24"x60" Adhesive Fabric Banner - '}</Typography>
                  {adsvPrice.discounted && (
                    <Typography
                      style={{
                        marginLeft: '4px',
                        textDecoration: 'line-through',
                        display: 'inline-block',
                        color: 'red',
                      }}
                    >
                      {formatCurrency(adsvProduct.price)}
                    </Typography>
                  )}
                  <Typography style={{ marginLeft: '4px', display: 'inline-block' }}>
                    {formatCurrency(adsvPrice.price)}
                  </Typography>
                  <Tooltip title={complete() ? 'Add To Cart' : 'Upload all photos to add to cart'}>
                    <span>
                      <Button
                        disabled={!complete()}
                        startIcon={<AddShoppingCartIcon />}
                        variant="contained"
                        onClick={() => handleAddToCart('24_60_ADSV')}
                      >
                        Add To Cart
                      </Button>
                    </span>
                  </Tooltip>
                </Stack>
              </Box>

              <Box>
                <Divider />
                <Stack
                  my={2}
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 0, sm: 2 }}
                  alignItems={'center'}
                >
                  <Typography style={{ display: 'inline-block' }}>
                    {'24"x60" Hanging Fabric Banner - '}
                  </Typography>
                  {polyPrice.discounted && (
                    <Typography
                      style={{
                        marginLeft: '4px',
                        textDecoration: 'line-through',
                        display: 'inline-block',
                        color: 'red',
                      }}
                    >
                      {formatCurrency(polyProduct.price)}
                    </Typography>
                  )}
                  <Typography style={{ marginLeft: '4px', display: 'inline-block' }}>
                    {formatCurrency(polyPrice.price)}
                  </Typography>
                  <Tooltip title={complete() ? 'Add To Cart' : 'Upload all photos to add to cart'}>
                    <span>
                      <Button
                        disabled={!complete()}
                        startIcon={<AddShoppingCartIcon />}
                        variant="contained"
                        onClick={() => handleAddToCart('24_60_POLY')}
                      >
                        Add To Cart
                      </Button>
                    </span>
                  </Tooltip>
                </Stack>
              </Box>
            </Stack>
          </Box>
        </>
      )}

      {uploadOpen && cropFile && (
        <PhotoCropDialog
          file={cropFile}
          rect={template.images[cropIndex].rect}
          origCrop={
            images[cropIndex]?.crop ? new PercentCrop({ ...images[cropIndex]?.crop }) : undefined
          }
          handleCropCanceled={() => setUploadOpen(false)}
          handleCropComplete={handleCropComplete}
          index={cropIndex}
        />
      )}
    </>
  );
};
