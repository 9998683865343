import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Order } from 'canvas-common';
import API from '../../api/API';
import { CenteredProgress } from '../basic/CenteredProgress';
import { OrderDisplay } from '../order/OrderDisplay';

export const OrderView = () => {
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);

  const navigate = useNavigate();

  let token: string | undefined | null = searchParams.get('token');
  if (!token) {
    token = localStorage.getItem('orderToken');
  }

  const apiClient = API();

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const data = await apiClient.getOrders(token as string);
        data.sort((a: any, b: any) => {
          return b.createdAt.localeCompare(a.createdAt);
        });
        setOrders(data);
      } catch (err) {}
      setLoading(false);
      navigate('/order', { replace: true });
    };

    if (token) {
      fetchInitialData();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <CenteredProgress />;
  }

  return <OrderDisplay orders={orders}></OrderDisplay>;
};
