import { Box, Link } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { UserData } from '../../context/UserData';
import { useContext } from 'react';
import { motion } from 'framer-motion';
import { Link as RouterLink } from 'react-router-dom';

export const PosterCard = ({ image, numImages }: any) => {
  const { setPosterScrollLocation } = useContext(UserData);

  const sm = numImages < 2 ? 12 : 6;
  const md = numImages < 2 ? 12 : 4;
  const lg = numImages < 2 ? 12 : numImages < 5 ? 4 : 3;

  const linkUrl = `/poster/${image.pk}`;

  return (
    <Grid
      key={image.pk}
      size={{ xs: 12, sm, md, lg }}
      component={motion.div}
      // animate={{ opacity: 1, scale: 1 }}
      // initial={{ opacity: 0, scale: 0.5 }}
      variants={{
        hidden: { opacity: 0, scale: 0.7 },
        visible: { opacity: 1, scale: 1 },
      }}
      transition={{ type: 'spring' }}
    >
      <Box position={'relative'} sx={{ aspectRatio: '1/1' }}>
        <Box
          justifyItems={'center'}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
          component="img"
          src={`https://templates.${process.env.REACT_APP_STAGE}.canvaspaints.com/images/${image.pk}_s.jpg`}
          alt="Custom poster prints to decorate your space."
        />
        <Link component={RouterLink} to={linkUrl} sx={{ textDecoration: 'none' }}>
          <Box
            component="span"
            sx={{
              position: 'absolute',
              border: 'none',
              backgroundColor: 'white',
              left: 0,
              top: 0,
              minWidth: '100%',
              minHeight: '100%',
              opacity: 0,
              '&:hover': { opacity: '50%', cursor: 'zoom-in' },
            }}
            onClick={() => {
              setPosterScrollLocation(window.pageYOffset);
            }}
          ></Box>
        </Link>
      </Box>
    </Grid>
  );
};
