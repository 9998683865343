import axios from 'axios';
import {
  Cart,
  PercentCrop,
  PersonalizedImage,
  PosterImage,
  Product,
  Promo,
  Template,
} from 'canvas-common';
import { CartUpdateBody } from 'canvas-common';

const API = () => {
  const client = axios.create({
    baseURL: `https://api.${process.env.REACT_APP_STAGE}.canvaspaints.com`,
  });
  return {
    getProducts: async (): Promise<Product[]> => {
      const res = await client.get('/cart/product');
      return res.data;
    },
    getTemplates: async (): Promise<Template[]> => {
      const res = await client.get('/template');
      return res.data;
    },
    getAllKeywords: async (): Promise<string[]> => {
      const res = await client.get('/template/image/keywords');
      return res.data;
    },
    getAllTemplateKeywords: async (): Promise<string[]> => {
      const res = await client.get('/template/template/keywords');
      return res.data;
    },
    getAllImages: async (): Promise<Partial<PosterImage>[]> => {
      const res = await client.get('/template/images');
      return res.data;
    },
    getAllPromos: async (): Promise<Promo[]> => {
      const res = await client.get('/cart/promo');
      return res.data.map((p: Partial<Promo>) => {
        return new Promo(p);
      });
    },

    getAnonCart: async (userId: string): Promise<Cart> => {
      const res = await client.get(`/cart/anonymous/${userId}`);
      return res.data;
    },
    getImage: async (pk: string): Promise<PosterImage> => {
      const res = await client.get(`/template/images/${pk}`);
      return res.data;
    },

    updateCart: async (body: CartUpdateBody): Promise<Cart> => {
      const res = await client.post(`/cart/anonymous/${body.cart.userUUID}`, body);
      return res.data;
    },
    deleteCart: async (userId: string): Promise<Cart> => {
      const res = await client.delete(`/cart/anonymous/${userId}`);
      return res.data;
    },
    applyPromoCode: async (userId: string, promoCode: string): Promise<Cart> => {
      const res = await client.post(`/cart/anonymous/promo-code`, { userId, promoCode });
      return res.data;
    },

    getS3BucketUploadUrl: async (userId: string, uuid: string) => {
      const res = await client.get(`/render/upload/${userId}/${uuid}`);
      return res.data;
    },
    renderTemplate: async (body: any) => {
      const res = await client.post('/render', body);
      return res.data;
    },
    cropAndResize: async (body: PersonalizedImage) => {
      const res = await client.post('/render/crop', body);
      return res.data;
    },
    cropAndResizePoster: async (
      s3Key: string,
      crop: PercentCrop | undefined
    ): Promise<PosterImage> => {
      const res = await client.post('/render/crop/poster', { s3Key, crop });
      return res.data;
    },
    getPurchase: async (purchaseId: string) => {
      const res = await client.get(`/cart/purchase/${purchaseId}`);
      return res.data;
    },
    getOrders: async (token: string) => {
      const res = await client.get(`/order/${token}`, {
        withCredentials: true,
      });
      return res.data;
    },
    getOrderForPaymentIntent: async (paymentIntentId: string) => {
      const res = await client.get(`/order/pi/${paymentIntentId}`);
      return res.data;
    },
    createOrderLink: async (email: string) => {
      const res = await client.post('/order/link', { email });
      return res.data;
    },
    unsubscribe: async (email: string) => {
      const res = await client.get(`/marketing/unsubscribe/${email}`);
      return res.data;
    },
    sendFeedback: async (body: any) => {
      const res = await client.post('/marketing/feedback', body);
      return res.data;
    },
  };
};

export default API;
