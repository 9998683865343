import { Helmet } from 'react-helmet';

export const TermsOfUseStructuredData = () => {
  return (
    <Helmet>
      <title>{`CanvasPaints - Terms Of Use`}</title>
      <meta name="description" content={`Terms Of Use for CanvasPaints`} />
      <meta
        name="keywords"
        content={[
          'terms of use',
          'legal',
          'posters',
          'banners',
          'custom',
          'fabric',
          'home decor',
        ].join(',')}
      />
    </Helmet>
  );
};
