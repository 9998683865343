import { Box, Button, Card, CardContent, Grid, Link, Stack, Typography } from '@mui/material';
import { Template } from 'canvas-common';
import { Link as RouterLink } from 'react-router-dom';

interface TemplateDisplayProps {
  template: Template;
  index: number;
}

export const TemplateDisplay = ({ template, index }: TemplateDisplayProps) => {
  const linkUrl = `/sports-banners/${template.data}`;

  return (
    <>
      <Grid key={index} item xs={6} sm={4} md={3} lg={2} display="flex" alignItems={'flex-end'}>
        <Card key={index} sx={{ width: '160px' }}>
          <CardContent style={{ justifyContent: 'center', display: 'flex' }}>
            <Stack>
              <Typography fontSize="12px" color="textSecondary" gutterBottom>
                {template.title}
              </Typography>
              <img
                // eslint-disable-next-line react/no-unknown-property
                fetchpriority="high"
                width="150px"
                height="375px"
                src={template.url}
                alt={template.title}
              />
              <Box width="128px">
                <Link component={RouterLink} to={linkUrl} sx={{ textDecoration: 'none' }}>
                  <Button
                    fullWidth
                    variant="contained"
                    sx={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}
                    aria-label="Details for this banner template"
                  >
                    Details
                  </Button>
                </Link>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    </>
  );
};
