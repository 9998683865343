export const DotSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="24px"
    viewBox="0 -960 960 960"
    width="24px"
    fill="inherit"
  >
    <path d="M480-480Zm0 280q-116 0-198-82t-82-198q0-116 82-198t198-82q116 0 198 82t82 198q0 116-82 198t-198 82Zm0-80q83 0 141.5-58.5T680-480q0-83-58.5-141.5T480-680q-83 0-141.5 58.5T280-480q0 83 58.5 141.5T480-280Z" />
  </svg>
);

export const DotFilledSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      d="M12,19c-1.9,0-3.6-0.7-4.9-2S5,13.9,5,12s0.7-3.6,2.1-5S10.1,5,12,5s3.6,0.7,5,2.1s2,3,2,5s-0.7,3.6-2,5
	S13.9,19,12,19z"
    />
  </svg>
);

export const PinterestSvg = (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="32.000000pt"
    height="32.000000pt"
    viewBox="0 0 32.000000 32.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,32.000000) scale(0.100000,-0.100000)"
      fill="inherit"
      stroke="none"
    >
      <path
        d="M92 304 c-100 -51 -121 -191 -41 -259 17 -14 34 -25 38 -25 8 0 24
80 26 130 1 31 12 50 30 50 21 0 25 -14 15 -51 -8 -26 -7 -37 5 -48 13 -13 17
-12 34 4 26 22 36 76 22 104 -7 11 -23 24 -36 27 -58 15 -121 -47 -96 -94 15
-28 -7 -42 -26 -17 -32 45 3 118 66 135 53 14 85 5 117 -33 22 -26 25 -37 19
-71 -10 -64 -56 -102 -102 -85 -17 6 -23 4 -27 -9 -2 -9 -7 -27 -11 -39 -6
-21 -3 -23 30 -23 116 0 198 123 150 224 -38 80 -139 118 -213 80z"
      />
    </g>
  </svg>
);
