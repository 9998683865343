import { useContext, useState } from 'react';
import { Container, Grid, Stack } from '@mui/material';
import { TemplateDisplay } from '../personalize/TemplateDisplay';
import { TemplateData } from '../../context/TemplateData';
import { KeywordPanel } from '../posters/KeywordPanel';
import { AllTemplatesStructuredData } from '../structuredData/AllTemplatesStructuredData';

export const AllTemplatesView = () => {
  const { templates } = useContext(TemplateData);
  const [keywords, setKeywords] = useState<string[]>([]);

  const filterTemplates = () => {
    let filtTemplates = [...templates];
    if (keywords.length > 0) {
      filtTemplates = filtTemplates.filter((i) => i.keywords?.some((kw) => keywords.includes(kw)));
    }
    return filtTemplates;
  };

  return (
    <>
      <AllTemplatesStructuredData />
      <Container>
        <Stack spacing="2">
          <KeywordPanel
            updateKeywords={(words) => setKeywords(words)}
            words={'TEMPLATE'}
            includeFavs={false}
            label="Search by Sport or Keyword"
          />

          <Grid container rowSpacing={3} columnSpacing={{ xs: 1, sm: 2 }} alignItems={'stretch'}>
            {filterTemplates().map((template, index) => {
              return <TemplateDisplay template={template} index={index} key={index} />;
            })}
          </Grid>
        </Stack>
      </Container>
    </>
  );
};
