import { Box, Button, Container, Stack, TextField, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { CloseButton } from '../basic/CloseButton';
import { useNavigate } from 'react-router-dom';
import { SnackbarContext } from '../../context/SnackbarContext';
import API from '../../api/API';

export const ContactUsView = () => {
  const [captchaValue, setCaptchaValue] = useState<string | null>(null);
  const [errorText, setErrorText] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const navigate = useNavigate();
  const { successSnack } = useContext(SnackbarContext);
  const apiClient = API();

  const handleCaptchaChange = (value: any) => {
    setCaptchaValue(value);
    setErrorText('');
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!captchaValue) {
      setErrorText('Please complete the reCAPTCHA');
      return;
    }
    if (!comments) {
      setErrorText('Please provide comments');
    }
    try {
      await apiClient.sendFeedback({ email, comments });
    } catch (e) {}
    setComments('');
    setEmail('');
    successSnack('Form submitted successfully!');
  };

  return (
    <Container maxWidth="lg" sx={{ bgcolor: '#faf2eb' }}>
      <Box py={4}>
        <CloseButton onClose={() => navigate('/home')} />
      </Box>

      <Box px={8} pb={8}>
        <Stack spacing={2}>
          <Typography variant="h4">CONTACT US</Typography>
          <Typography variant="body1">
            Fill out the form below with any comments, questions and/or suggestions you have for the
            CanvasPaints team.
          </Typography>
          <Typography variant="body1">
            Provide your email address if you would like us to respond to your comment/question.
          </Typography>
          <TextField
            label="Comments/Suggestions"
            value={comments}
            rows={4}
            multiline
            fullWidth
            variant="filled"
            onChange={(e) => {
              setComments(e.target.value);
              setErrorText('');
            }}
          ></TextField>
          <TextField
            label="Email Address"
            variant="filled"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />

          <form onSubmit={handleSubmit}>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_KEY!}
              onChange={handleCaptchaChange}
            />
            <br />
            {errorText && <Typography color="red">{errorText}</Typography>}
            <Button variant="contained" type="submit">
              Submit
            </Button>
          </form>
        </Stack>
      </Box>
    </Container>
  );
};
