import { Helmet } from 'react-helmet';

export const AllTemplatesStructuredData = () => {
  return (
    <Helmet>
      <title>{`Personalized Sports Banners`}</title>
      <meta
        name="description"
        content={`Personalized fabric sports banners. Inspire your young athlete!`}
      />
      <meta
        name="keywords"
        content={[
          'banner',
          'personalized',
          'youth sports',
          'printed',
          'home decor',
          'wall hanging',
        ].join(',')}
      />
    </Helmet>
  );
};
