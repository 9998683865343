import { Helmet } from 'react-helmet';

export const AllPostersStructuredData = () => {
  return (
    <Helmet>
      <title>{`Custom Posters To Transform Your Space`}</title>
      <meta
        name="description"
        content={`Custom 24x36 adhesive fabric posters to transform your space`}
      />
      <meta
        name="keywords"
        content={['poster', 'custom', 'fabric', 'printed', 'adhesive', 'home decor'].join(',')}
      />
    </Helmet>
  );
};
