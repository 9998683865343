import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const SportsBannerDescription = () => {
  const navigate = useNavigate();

  return (
    <Stack>
      <Typography variant="h4" mb={2}>
        Personalized Sports Banners
      </Typography>
      <Typography component="ul">
        <Typography variant="h6" component="li">
          Available as an adhesive fabric banner or hanging fabic banner with grommets for hanging
        </Typography>
        <Typography variant="h6" component="li">
          Templates with professional team logos and colors
        </Typography>
        <Typography variant="h6" component="li">
          Sports: Baseball, Basketball, Football, Hockey and Soccer
        </Typography>
        <Typography variant="h6" component="li">
          Huge 24" x 60" size to showcase your athlete
        </Typography>
        <Typography variant="h6" component="li">
          Soft fabric texture
        </Typography>
        <Typography variant="h6" component="li">
          Starting at $79.95 + $4.95 shipping
        </Typography>
      </Typography>
      <Stack mt={4} spacing={2}>
        <Stack direction={'row'}>
          <Box flex="1 1 auto" />

          <Box
            component="img"
            src="soccer.webp"
            alt="Personalized sports banners"
            maxWidth={'300px'}
          />
          <Box flex="1 1 auto" />
        </Stack>

        <Stack direction={'row'}>
          <Box flex="1 1 auto" />

          <Box>
            <Button variant="contained" color="secondary" onClick={() => navigate('/posters')}>
              Create Your Banner
            </Button>
          </Box>
          <Box flex="1 1 auto" />
        </Stack>
      </Stack>
    </Stack>
    /*



    <Stack direction="row">
      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />

      <Box
        mt={3}
        component={Stack}
        spacing={2}
        direction="column"
        justifyContent="center"
        sx={{ padding: '10px', minHeight: { xs: 300, lg: 600 } }}
      >
        <Stack direction="row" alignItems="center" spacing={4}>
          <Typography
            variant="h4"
            maxWidth="180px"
            sx={{
              fontFamily: 'Instrument Serif',
              textAlign: 'center',
            }}
          >
            Turn your young athlete into a superstar! Custom sports banners featuring pro team logos
            and your athlete's photos—inspiration for a lifetime!
          </Typography>

          <Box
            component="img"
            src="soccer.webp"
            alt="Personalized sports banners"
            maxHeight={{ xs: '500px', lg: '700px' }}
          />
        </Stack>

        <Typography
          variant="h4"
          sx={{
            fontFamily: 'Instrument Serif',
            textAlign: 'center',
          }}
        >
          Sports Banners
        </Typography>

        <Stack direction={'row'} justifyContent={'center'} spacing={2}>
          <Button variant="contained" color="secondary" onClick={() => navigate('/sports-banners')}>
            Get Started
          </Button>
        </Stack>
      </Box>

      <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' } }} />
    </Stack>
    */
  );
};
