import {
  Box,
  Button,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  SvgIcon,
  Tooltip,
  Typography,
} from '@mui/material';

import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CenteredProgress } from '../basic/CenteredProgress';
import API from '../../api/API';
import { CartItem, PosterImage, Product, Promo } from 'canvas-common';
import { BorderColorSelector } from '../posters/BorderColorSelector';
import { CloseButton } from '../basic/CloseButton';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import { v4 } from 'uuid';
import { UserData } from '../../context/UserData';
import { SnackbarContext } from '../../context/SnackbarContext';
import { ProductData } from '../../context/ProductData';
import { rgbToHex } from '../../utils/rgbToHex';
import { PosterStructuredData } from '../structuredData/PosterSturcturedData';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import { PromoData } from '../../context/PromoData';
import { formatCurrency } from '../../styles/Formatters';
import { PinterestSvg } from '../../styles/Icons';

export const PosterView = () => {
  const [busy, setBusy] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [poster, setPoster] = useState<PosterImage | null>(null);
  const navigate = useNavigate();
  const [color, setColor] = useState<[number, number, number] | null>(null);

  const { cart, syncCartItems, addFavorite, removeFavorite, favorites } = useContext(UserData);

  const { successSnack } = useContext(SnackbarContext);
  const { productsLoading, getProduct }: any = useContext(ProductData);
  const { promosLoading, promo } = useContext(PromoData);

  const { posterId } = useParams();
  if (!posterId) navigate('/');

  const apiClient = API();

  useEffect(() => {
    const loadPoster = async () => {
      if (!posterId) return;
      const poster = await apiClient.getImage(posterId);
      setPoster(poster);
      setBusy(false);
    };

    loadPoster();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [posterId]);

  const setTheColor = (color: [number, number, number] | null) => {
    setColor(color);
  };

  const handleAddToCart = () => {
    const product: Product = getProduct(
      poster?.orientation === 'square' ? '24_24_ADSV' : '24_36_ADSV'
    );
    const cartItem: CartItem = {
      id: v4(),
      product,
      posterImage: { ...poster, ...(color && { borderColor: color }) },
      quantity: 1,
    };
    syncCartItems({
      cart: { ...cart, cartItems: [...cart.cartItems!, cartItem] },
      createOrUpdatePayment: false,
      calculateTax: false,
    });
    successSnack('Nice! The poster has been added to your shopping cart.');
    navigate('/posters');
  };

  if (productsLoading || promosLoading) return <CenteredProgress />;

  const xs = color ? `12px solid ${rgbToHex(color)}` : 'none';
  const sm = color ? `20px solid ${rgbToHex(color)}` : 'none';

  const isFavorite = poster && favorites.includes(poster.pk!);

  const square = poster && Math.abs(poster!.width! - poster!.height!) < 0.1;
  const portrait = poster && !square && poster.height! > poster.width!;

  const product = getProduct(square ? '24_24_ADSV' : '24_36_ADSV');
  const productPrice = Promo.getCurrentPrice(promo, product);

  const description = encodeURIComponent(
    `Get this image printed on an adhesive fabric poster at CanvasPaints.com. Select from 100s of beautiful curated images including this one - ${
      poster?.description || 'Beautiful Image'
    }. Can't find one you love? Upload your own photo and get it printed today!`
  );
  const site = process.env.REACT_APP_STAGE === 'dev' ? 'dev' : 'wwww';
  const pinterestUrl = `https://www.pinterest.com/pin/create/button/?url=http://${site}.canvaspaints.com/poster/${poster?.pk}&media=https://templates.${process.env.REACT_APP_STAGE}.canvaspaints.com/images/${poster?.pk}_l.jpg&description=${description}`;

  return (
    <>
      {busy && <CenteredProgress />}
      {!busy && (
        <>
          <PosterStructuredData poster={poster!} />
          <CloseButton onClose={() => navigate('/posters')} />
          <Box display="flex" justifyContent="center" alignItems="center">
            <Stack direction="column" spacing={2}>
              <Stack
                direction="row"
                sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
              >
                <Typography variant="h6">{poster?.description || ''}</Typography>

                <Stack direction="row" spacing={0} alignItems={'center'}>
                  <Tooltip title={'Save to Pinterest'}>
                    <a
                      data-pin-do="buttonPin"
                      data-pin-count="beside"
                      data-pin-save="false"
                      href={pinterestUrl}
                    >
                      <IconButton aria-label="pin it">
                        <SvgIcon sx={{ fill: 'red' }}>{PinterestSvg}</SvgIcon>
                      </IconButton>
                    </a>
                  </Tooltip>

                  <Tooltip title={isFavorite ? 'Remove from favorites' : 'Add to favorites'}>
                    <IconButton
                      sx={{
                        color: 'red',
                      }}
                      size="large"
                      aria-label="favorite"
                      onClick={(e) => {
                        if (isFavorite) {
                          removeFavorite(poster.pk!);
                        } else {
                          addFavorite(poster!.pk!);
                        }
                        e.stopPropagation();
                      }}
                    >
                      {isFavorite && <FavoriteIcon />}
                      {!isFavorite && <FavoriteBorderOutlinedIcon />}
                    </IconButton>
                  </Tooltip>
                </Stack>
              </Stack>
              <Box>
                {loading && (
                  <Skeleton
                    width={portrait ? '466px' : '700px'}
                    height={square ? '700px' : portrait ? '700px' : '466px'}
                  ></Skeleton>
                )}
                <Box
                  maxWidth="min(90vw, 700px)"
                  maxHeight="700px"
                  component="img"
                  src={`https://templates.${process.env.REACT_APP_STAGE}.canvaspaints.com/images/${
                    poster!.pk
                  }_l.jpg`}
                  sx={{ border: { xs, sm }, display: loading ? 'none' : 'block' }}
                  onLoad={() => setLoading(false)}
                />
              </Box>

              <BorderColorSelector colorPalette={poster!.colorPalette!} setColor={setTheColor} />

              <Box>
                <Divider />
                <Stack
                  mt={2}
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 0, sm: 2 }}
                  alignItems={'center'}
                >
                  <Typography>{product.name} - </Typography>
                  {productPrice.discounted && (
                    <Typography
                      style={{
                        marginLeft: '4px',
                        textDecoration: 'line-through',
                        display: 'inline-block',
                        color: 'red',
                      }}
                    >
                      {formatCurrency(product.price)}
                    </Typography>
                  )}
                  <Typography style={{ marginLeft: '4px', display: 'inline-block' }}>
                    {formatCurrency(productPrice.price)}
                  </Typography>
                </Stack>
                <Box mt={2}>
                  <Button
                    startIcon={<AddShoppingCartIcon />}
                    variant="contained"
                    onClick={handleAddToCart}
                  >
                    Add To Cart
                  </Button>
                </Box>
              </Box>
            </Stack>
          </Box>
        </>
      )}
    </>
  );
};
