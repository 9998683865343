import { Box, Button, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const PosterDescription = () => {
  const navigate = useNavigate();

  return (
    <Stack>
      <Typography variant="h4" mb={2}>
        Adhesive Fabric Posters
      </Typography>
      <Typography component="ul">
        <Typography variant="h6" component="li">
          No nails, thumbtacks or holes in your walls
        </Typography>
        <Typography variant="h6" component="li">
          Large 24" x 36" size for a bold impact
        </Typography>
        <Typography variant="h6" component="li">
          Easily repositioned
        </Typography>
        <Typography variant="h6" component="li">
          Leaves no adhesive residue
        </Typography>
        <Typography variant="h6" component="li">
          Soft fabric texture
        </Typography>
        <Typography variant="h6" component="li">
          Select from our curated catalog of images or upload your favorite photo to personalize
          your space
        </Typography>
        <Typography variant="h6" component="li">
          $49.95 + $4.95 shipping
        </Typography>
      </Typography>
      <Stack spacing={2}>
        <Box
          px={3}
          pt={3}
          component="img"
          src="poster.webp"
          alt="Custom poster prints to decorate your space."
        />
        <Stack direction={'row'}>
          <Box flex="1 1 auto" />
          <Box>
            <Button variant="contained" color="secondary" onClick={() => navigate('/posters')}>
              Explore Posters
            </Button>
          </Box>
          <Box flex="1 1 auto" />
        </Stack>
      </Stack>
    </Stack>
  );
};
