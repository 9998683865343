import { Helmet } from 'react-helmet';

export const PrivacyPolicyStructuredData = () => {
  return (
    <Helmet>
      <title>{`CanvasPaints - Privacy Policy`}</title>
      <meta name="description" content={`Privacy Policy for CanvasPaints`} />
      <meta
        name="keywords"
        content={[
          'privacy',
          'policy',
          'legal',
          'posters',
          'banners',
          'custom',
          'fabric',
          'home decor',
        ].join(',')}
      />
    </Helmet>
  );
};
