import { createContext, useEffect, useState } from 'react';
import { Product } from 'canvas-common';
import API from '../api/API';

type ProductDataContextDefaultValue = {
  products: Product[];
  getProduct: (sku: string) => Product;
  productsLoading: boolean;
};

export const ProductData = createContext<ProductDataContextDefaultValue>({
  products: [],
  getProduct: (sku: string) => new Product(),
  productsLoading: false,
});

export const ProductDataProvider = ({ children }: any) => {
  const apiClient = API();

  const [products, setProducts] = useState<Product[]>([]);
  const [productsLoading, setProductsLoading] = useState<boolean>(true);

  useEffect(() => {
    const initializeProducts = async () => {
      const beProducts = await apiClient.getProducts();
      beProducts.sort((a, b) => {
        return b.data.localeCompare(a.data);
      });
      setProducts(beProducts);
      setProductsLoading(false);
    };

    initializeProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProduct = (sku: string) => {
    return products.find((p) => p.data === sku)!;
  };

  const value = {
    products,
    getProduct,
    productsLoading,
  };

  return <ProductData.Provider value={value}>{children}</ProductData.Provider>;
};
