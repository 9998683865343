import { Helmet } from 'react-helmet';
import { DateTime } from 'luxon';
import { PosterImage, Promo } from 'canvas-common';
import { PromoData } from '../../context/PromoData';
import { useContext } from 'react';
import { ProductData } from '../../context/ProductData';

interface PosterStructuredDataProps {
  poster: PosterImage;
}

const getUrl = (pk: string) => {
  return `https://templates.${process.env.REACT_APP_STAGE}.canvaspaints.com/images/${pk}_s.jpg`;
};

export const PosterStructuredData = ({ poster }: PosterStructuredDataProps) => {
  const { promo } = useContext(PromoData);
  const { getProduct } = useContext(ProductData);

  const sku = poster.orientation === 'square' ? '24_24_ADSV' : '24_36_ADSV';

  const product = getProduct(sku);
  const productPrice = Promo.getCurrentPrice(promo, product);

  const expireDate = productPrice.discounted
    ? DateTime.fromSeconds(promo!.ttl).toFormat('yyyy-MM-dd')
    : DateTime.now().plus({ days: 14 }).toFormat('yyyy-MM-dd');

  return (
    <Helmet>
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org/',
          '@type': 'Product',
          name: 'Custom 24x36 adhesive fabric poster.',
          image: [getUrl(poster.pk!)],
          brand: {
            '@type': 'Brand',
            name: 'CanvasPaints',
          },
          sku: `24_36_ADSV_${poster.index!.toLocaleString('en-US', {
            minimumIntegerDigits: 4,
          })}`,
          description: `${poster.description}`,
          offers: {
            '@type': 'Offer',
            price: Number(productPrice.price.toFixed(2)),
            priceCurrency: 'USD',
            priceValidUntil: `${expireDate}`,
            availability: 'InStock',
            ...(productPrice.discounted && {
              priceSpecification: {
                '@type': 'UnitPriceSpecification',
                priceType: 'https://schema.org/StrikethroughPrice',
                price: product.price,
                priceCurrency: 'USD',
              },
            }),
          },
        })}
      </script>
      <title>{`Poster - ${poster.description}`}</title>
      <meta
        name="description"
        content={`Custom 24x36 adhesive fabric poster. ${poster.description}`}
      />
      <meta name="keywords" content={['poster', ...(poster.keywords || [])].join(',')} />
    </Helmet>
  );
};
