import { Box, Button, Stack, Typography } from '@mui/material';
import { CloseButton } from '../basic/CloseButton';
import { useNavigate } from 'react-router-dom';
import { Template } from 'canvas-common';

interface TemplateCardProps {
  template: Template;
  setPersonalized: (personalized: boolean) => void;
}

export const TemplateCard = ({ template, setPersonalized }: TemplateCardProps) => {
  const navigate = useNavigate();

  return (
    <>
      <CloseButton onClose={() => navigate('/sports-banners')} />

      <Stack
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction={{ xs: 'column', md: 'row' }}
      >
        <Stack alignItems="center" justifyContent="center">
          <Box component="img" height={500} src={template.url} alt={template.title} />
        </Stack>

        <Stack px={4} alignItems="flex-start" justifyContent="flex-start">
          <Typography fontSize={20} color="textSecondary">
            {template.title}
          </Typography>
          <Typography fontSize={18} color="textSecondary">
            Sport: <b>{template.sport}</b>
          </Typography>
          <Typography fontSize={18} color="textSecondary">
            Team: <b>{template.team}</b>
          </Typography>
          <Typography fontSize={18} color="textSecondary" gutterBottom>
            Number of Photos: {template.images.length}
          </Typography>
          <Typography fontSize={18} color="textSecondary" gutterBottom>
            This design is available in two products:
          </Typography>
          <Typography component={'ul'} fontSize={18} color="textSecondary" gutterBottom>
            <li>Hanging Fabric Banner</li>
            <li>Adhesive Fabric Banner</li>
          </Typography>
          <Typography fontSize={18} color="textSecondary" gutterBottom>
            Shown with stock photos
          </Typography>
          <Typography fontSize={18} color="textSecondary" gutterBottom>
            Personalize it with your photos and order today!
          </Typography>

          <Button
            onClick={() => setPersonalized(true)}
            variant="contained"
            sx={{ alignSelf: { xs: 'center', sm: 'flex-start' }, width: 200, mt: 2 }}
            aria-label="Personalize this banner template"
          >
            Personalize
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
