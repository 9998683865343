import Grid from '@mui/material/Grid2';
import { HeroTextBox } from '../home/HeroTextBox';
import { ImageGallery } from '../home/ImageGallery';
import { PosterDescription } from '../home/PosterDescription';
import { SportsBannerDescription } from '../home/SportsBannerDescription';
import { Box, Container, Divider, Stack, Typography } from '@mui/material';
import { HomePageStructuredData } from '../structuredData/HomePageStructuredData';
import { SportsBannerImage } from '../home/SportsBannerImage';

export const HomeView = () => {
  return (
    <>
      <HomePageStructuredData />
      <Container maxWidth="lg" sx={{ display: 'flex', justifyContent: 'center' }}>
        <Grid container justifyContent={{ xs: 'center', lg: 'space-between' }}>
          <Grid size={{ xs: 12, lg: 5 }}>
            <HeroTextBox text="Transform your space into a personal masterpiece with our custom prints — where every wall tells your story!" />
          </Grid>
          <Grid size={{ xs: 12, lg: 7 }}>
            <ImageGallery />
          </Grid>
          <Grid size={{ xs: 12 }} mt={8}>
            <Typography textAlign="center" fontWeight="bold" variant="h3">
              Our Products
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }} mt={4}>
            <PosterDescription />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }} mt={4}>
            <SportsBannerDescription />
          </Grid>

          <Box mt={4} width="100%">
            <Divider />
          </Box>

          <Grid size={{ xs: 12 }}>
            <SportsBannerImage />
          </Grid>

          <Box my={4} width="100%">
            <Divider />
          </Box>

          <Grid size={{ xs: 12, md: 6 }}>
            <Stack spacing={2}>
              <Typography variant="h4" textAlign={'center'}>
                Our Adhesive Fabric
              </Typography>
              <Typography variant="h5">
                See how easy it is to remove our adhesive fabric posters and banners from a painted
                wall.
              </Typography>
              <Typography variant="h5">No residue!</Typography>
            </Stack>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <video width={'360px'} height={'360px'} autoPlay loop muted playsInline>
              <source src={'./adhesive.webm'} type="video/webm" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};
