import { Paper, Typography, Button, Box, Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import API from '../../api/API';

export const UnsubscribeView = () => {
  const apiClient = API();
  const { email } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = async () => {
      if (email) {
        await apiClient.unsubscribe(email);
      }
    };
    unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper sx={{ margin: '12px', padding: '20px' }}>
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={12}>
          <Typography variant={'h5'} align="center">
            We're sorry to see you go!
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center">We have unsubscribed {email} from our email list.</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box justifySelf={'center'}>
            <Button variant="contained" onClick={() => navigate('/')}>
              Home
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};
