import { createContext, useEffect, useState } from 'react';
import { Template } from 'canvas-common';
import API from '../api/API';

type TemplateDefaultValue = {
  templates: Template[];
  getTemplate: (templateId: string) => Template | undefined;
  templatesLoading: boolean;
};

export const TemplateData = createContext<TemplateDefaultValue>({
  templates: [],
  getTemplate: () => {
    return undefined;
  },
  templatesLoading: false,
});

export const TemplateDataProvider = ({ children }: any) => {
  const apiClient = API();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [templatesLoading, setTemplatesLoading] = useState<boolean>(true);

  useEffect(() => {
    const intializeTemplates = async () => {
      const beTemplates = await apiClient.getTemplates();
      setTemplates(beTemplates);
      setTemplatesLoading(false);
    };

    intializeTemplates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTemplate = (templateId: string) => {
    return templates.find((t) => t.data === templateId);
  };

  const value = {
    templates,
    getTemplate,
    templatesLoading,
  };

  return <TemplateData.Provider value={value}>{children}</TemplateData.Provider>;
};
