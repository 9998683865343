import { createContext, useEffect, useState } from 'react';
import API from '../api/API';

type ImageKeywordDataContextDefaultValue = {
  imageKeywords: string[];
  imageKeywordsLoading: boolean;
};

export const ImageKeywordData = createContext<ImageKeywordDataContextDefaultValue>({
  imageKeywords: [],
  imageKeywordsLoading: false,
});

export const ImageKeywordDataProvider = ({ children }: any) => {
  const apiClient = API();

  const [imageKeywords, setImageKeywords] = useState<string[]>([]);
  const [imageKeywordsLoading, setImageKeywordsLoading] = useState<boolean>(true);

  useEffect(() => {
    const initializeKeywords = async () => {
      const imageKeywords = await apiClient.getAllKeywords();
      imageKeywords.sort((a, b) => {
        return a.localeCompare(b);
      });
      setImageKeywords(imageKeywords);
      setImageKeywordsLoading(false);
    };

    initializeKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    imageKeywords,
    imageKeywordsLoading,
  };

  return <ImageKeywordData.Provider value={value}>{children}</ImageKeywordData.Provider>;
};
