import { Box, Button, Container, Grid, Stack, Typography } from '@mui/material';
import { CloseButton } from '../basic/CloseButton';
import { useNavigate } from 'react-router-dom';
import { AboutStructuredData } from '../structuredData/AboutStructuredData';

export const AboutView = () => {
  const navigate = useNavigate();

  return (
    <>
      <AboutStructuredData />
      <Container maxWidth="lg" sx={{ bgcolor: '#faf2eb' }}>
        <Box py={4}>
          <CloseButton onClose={() => navigate('/home')} />
        </Box>

        <Stack px={8} pb={8} spacing={2}>
          <Typography variant="h4">About CanvasPaints</Typography>

          <Typography variant="h6">Printed in the USA using archival inks</Typography>
          <Typography variant="body1">
            Our products are printed in the USA on high-quality fabric materials using
            professional-grade printers. We use archival inks that are designed to last for 75 years
            without fading or loss of color.
          </Typography>

          <Typography variant="h6">Curated collection of images</Typography>
          <Typography variant="body1">
            We offer a large selection of images that are curated by our team of artists. Our
            collection includes a wide range of styles and subjects, so you can find the perfect
            image for your space.
          </Typography>

          <Grid container spacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <Box
                px={3}
                maxWidth={300}
                component="img"
                src="poster1.webp"
                alt="Buffalo in snow poster."
              />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Box
                px={3}
                maxWidth={300}
                component="img"
                src="poster2.webp"
                alt="Half dome at sunset poster."
              />
            </Grid>

            <Grid item xs={12} md={6} lg={4}>
              <Box
                px={3}
                maxWidth={300}
                component="img"
                src="poster3.webp"
                alt="Peaceful lake scene poster."
              />
            </Grid>
          </Grid>

          <Typography variant="h6">Personalized products</Typography>
          <Typography variant="body1">
            Can't find the perfect image in our curated collection? Upload your own image to create
            a custom poster that is unique to you.
          </Typography>

          <Typography variant="h6">Easy returns</Typography>
          <Typography variant="body1">
            If you are not completely satisfied with your purchase, please contact us within 30 days
            of delivery to start your return or exchange.
          </Typography>

          <Stack pt={5} direction={'row'} spacing={5}>
            <Button variant="contained" color="secondary" onClick={() => navigate('/posters')}>
              Create Your Poster
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => navigate('/sports-banners')}
            >
              Create Your Personalized Sports Banner
            </Button>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};
