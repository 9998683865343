import { Helmet } from 'react-helmet';

export const AboutStructuredData = () => {
  return (
    <Helmet>
      <title>{`CanvasPaints - About`}</title>
      <meta name="description" content={`About the CanvasPaints company`} />
      <meta
        name="keywords"
        content={['about', 'company', 'posters', 'banners', 'custom', 'fabric', 'home decor'].join(
          ','
        )}
      />
    </Helmet>
  );
};
