import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import { Header } from './Header';
import { Footer } from './Footer';
import { AllPostersView } from '../views/AllPostersView';
import { AboutView } from '../views/AboutView';
import { AllTemplatesView } from '../views/AllTemplatesView';
import { OrderView } from '../views/OrderView';
import { TemplateView } from '../views/TemplateView';
import { HomeView } from '../views/HomeView';
import { BasePage } from '../views/BasePage';
import { PosterView } from '../views/PosterView';
import { CssBaseline } from '@mui/material';
import { CustomPosterConfigurator } from '../posters/CustomPosterConfigurator';
import { TermsOfUseView } from '../views/TermsOfUseView';
import { PrivacyPolicyView } from '../views/PrivacyPolicyView';
import React from 'react';
import { UnsubscribeView } from '../views/UnsubscribeView';
import { ContactUsView } from '../views/ContactUsView';

const CartView = React.lazy(() => import('../views/CartView'));

const AppLayout = () => (
  <>
    <CssBaseline />
    <Header />
    <Outlet />
    <BasePage>
      <Footer />
    </BasePage>
  </>
);

export const Router = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route path="/home" element={<HomeView />} />
          <Route path="/sports-banners" element={<AllTemplatesView />} />
          <Route path="/sports-banners/:templateId" element={<TemplateView />} />
          <Route path="/marketing/unsubscribe/:email" element={<UnsubscribeView />} />
          <Route path="/posters" element={<AllPostersView />} />
          <Route path="/poster/custom" element={<CustomPosterConfigurator />} />
          <Route path="/poster/:posterId" element={<PosterView />} />
          <Route path="/create" element={<AllTemplatesView />} />
          <Route path="/about" element={<AboutView />} />
          <Route path="/contact" element={<ContactUsView />} />
          <Route path="/cart" element={<CartView />} />
          <Route path="/order" element={<OrderView />} />
          <Route path="/terms" element={<TermsOfUseView />} />
          <Route path="/privacy" element={<PrivacyPolicyView />} />
          <Route path="/sports_banners" element={<Navigate to="/sports-banners" replace />}></Route>
          <Route
            path="/sports_banners/:templateId"
            element={<Navigate to="/sports_banners/:templateId" replace />}
          ></Route>
          <Route path="/" element={<Navigate to="/home" replace />}></Route>
          <Route path="*" element={<Navigate to="/home" replace />}></Route>
        </Route>
      </Routes>
    </>
  );
};
