import { createContext, useEffect, useState } from 'react';
import API from '../api/API';

type TemplateKeywordDataContextDefaultValue = {
  templateKeywords: string[];
  templateKeywordsLoading: boolean;
};

export const TemplateKeywordData = createContext<TemplateKeywordDataContextDefaultValue>({
  templateKeywords: [],
  templateKeywordsLoading: false,
});

export const TemplateKeywordDataProvider = ({ children }: any) => {
  const apiClient = API();

  const [templateKeywords, setTemplateKeywords] = useState<string[]>([]);
  const [templateKeywordsLoading, setTemplateKeywordsLoading] = useState<boolean>(true);

  useEffect(() => {
    const initializeKeywords = async () => {
      const templateKeywords = await apiClient.getAllTemplateKeywords();
      templateKeywords.sort((a, b) => {
        return a.localeCompare(b);
      });
      setTemplateKeywords(templateKeywords);
      setTemplateKeywordsLoading(false);
    };

    initializeKeywords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = {
    templateKeywords,
    templateKeywordsLoading,
  };

  return <TemplateKeywordData.Provider value={value}>{children}</TemplateKeywordData.Provider>;
};
