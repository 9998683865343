import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { Template } from 'canvas-common';
import { TemplateData } from '../../context/TemplateData';
import { PersonalizeDialog } from '../personalize/PersonalizeDialog';
import { TemplateStructuredData } from '../structuredData/TemplateStructuredData';
import { TemplateCard } from '../cards/TemplateCard';
import { CenteredProgress } from '../basic/CenteredProgress';

export const TemplateView = () => {
  const navigate = useNavigate();
  const [personalize, setPersonalized] = useState(false);
  const [template, setTemplate] = useState<Template | undefined>();
  const { getTemplate, templatesLoading } = useContext(TemplateData);

  const { templateId } = useParams();
  if (!templateId) navigate('/');

  useEffect(() => {
    const fetchTemplate = async () => {
      if (templatesLoading) return;

      const t: Template | undefined = getTemplate(templateId!);
      if (!t) navigate('/');

      setTemplate(t);
    };

    fetchTemplate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templatesLoading]);

  const handlePersonalizeClosed = () => {
    setPersonalized(false);
  };

  if (!template) {
    return <CenteredProgress label={'loading...'} />;
  }

  if (personalize) {
    return (
      <>
        <TemplateStructuredData template={template} />
        <PersonalizeDialog template={template} onClose={handlePersonalizeClosed} />
      </>
    );
  }

  return (
    <>
      <TemplateStructuredData template={template} />
      <TemplateCard template={template} setPersonalized={setPersonalized} />
    </>
  );
};
